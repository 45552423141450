<style>
    .van-image-preview__cover, .van-image-preview__image{
        width: 100%;
    }
    .img-preview-box{ color:#fff;padding:1rem 3%; width: 94%}
    .img-preview-box .img-preview-title{ font-size: 1rem;margin-top: 1.5rem;width: 100%;overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;  /*要显示的行数*/
      -webkit-box-orient: vertical; }
    .img-preview-box .img-preview-footer{
        position: fixed;bottom: 1rem;width: 94%;
    }
    .img-preview-box .img-preview-index{
        margin-bottom: 1.5rem;font-size: 1rem;text-align: center
    }
    .img-preview-box .img-preview-foot-opt{
        display: flex;align-items: center;justify-content: space-between;
        font-size: 1rem;font-weight: 300;
    }
    .img-preview-box .add-cart{
        font-size: 2.4rem;margin-right: -0.5rem;
        -webkit-line-clamp: 2;
        background-image: -webkit-linear-gradient(180deg,rgba(255,200,13,1) 0%,rgba(255,130,0,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .img-preview-box .add-cart::before{
        display:block
    }
</style>
<template>
    <div>
        <van-image-preview
            v-model="show"
            :showIndex="true"
            :images="images"
            @change="handleChangeIndex">
            <template slot="cover">
                <div class="img-preview-box">
                    <!-- <div class="img-preview-title">
                        {{goodInfo.name}}
                    </div> -->
                    <div class="img-preview-footer">
                        <!-- <div class="img-preview-index">{{index+1}}/{{images.length}}</div> -->
                        <!-- <div class="img-preview-foot-opt">
                            <div class="img-preview-foot-left">
                                <span style="font-size: 1.1rem;">{{goodInfo.price}}</span>
                            </div>
                            <div class="img-preview-foot-right">
                                <van-icon @click="handleClickAddCart" class="add-cart" name="cart-circle-o" color="#FF976A" />
                            </div>
                        </div> -->
                    </div>
                </div>
            </template>
        </van-image-preview>
    </div>
</template>
<script>
export default {
    name:"img-preview",
    props:{
        propShow:Boolean,
        preViewList:Object
    },
    watch:{
        //监听父组件传来的show
        propShow: {
            immediate: true,
            handler (v) {
                this.show = v;
            }
        },
        //监听父组件传来的show
        preViewList: {
            handler (v) {
                this.images = []
                this.data = v;
                let data = v;
                //赋值给goodInfo
                this.goodInfo = {
                    name:v.name,
                    price:v.price,
                    spuId:v.spuId
                }
                if(data.spu_imgs){
                    data.spu_imgs.forEach(item=>{
                        this.images.push(item)
                    })
                }
            }
        },
        //修改父组件的show
        show(v){
            if(v === false){
                this.$emit("handleClickPropShow")
            }
        }
    },
    data(){
        return{
            show:false,
            index: 0,
            images:[],
            data: [],
            goodInfo:{
                name:"",
                price:0,
                spuId:""
            }
        }
    },
    methods:{
        handleChangeIndex(index){
            this.index = index;
            // this.goodInfo = {
            //     name:this.data[index].name,
            //     price:this.data[index].price,
            //     unit:this.data[index].unit
            // }
        },
        handleClickAddCart(){
            this.show = !this.show
            this.$emit("handleClickAddCart",1,this.goodInfo.spuId)
        }
    }
}
</script>