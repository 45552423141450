<style lang="less">
    .van-sku-container{
        max-height: 95%;
    }
    .van-sku-messages{
        padding-bottom:0.5rem
    }
    .skuPopup .good-title{
        padding: 1rem;
        color: #3F4562;
        font-weight: bold;
    }
    .skuPopup .good-price{
        color: red;
        font-weight: bold;
        margin-top: 0;
    }
    .skuPopup .van-sku-header__goods-info{
        justify-content: flex-start;
    }
    .skuPopup .extra-sku{
        font-size: 0.875rem;
        padding: 1rem;
    }
    .skuPopup .van-picker__columns{
        font-size: 0.6rem;
    }
    .skuPopup .good-time{
        padding:0 1rem 0.5rem;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .skuPopup .good-time::after{
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        border-bottom: 0.0625rem solid #ebedf0;
        -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
        transform: scale(.5);
    }
    .skuPopup .good-time-tip{
        padding:0 0.2rem;
        color: #fff;
        background:linear-gradient(90deg,rgba(255,87,43,1) 0%,rgba(255,0,23,1) 100%);
        border-radius:2px;
        margin-right: 0.3rem;
    }
    .spu_g_attr{
        margin: 0.5rem 0;
        line-height: 1.2rem;
    }
    //特殊镜片品
    .extra-sku_special{
        padding: 1rem 0.5rem;
        display: flex;
        justify-content: space-between;
        .van-cell{
            padding:0;
            border: .08rem solid #D8D8D8;
            border-radius: .3rem;
        }
        &-item{
            font-size: 0.8rem;
            border-radius: 5px;
            width: 49%;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, .2)
        }
        &-tit{
            font-size: 1rem;
            text-align: center;
            padding: 0.5rem 0;
        }
        &-switch{
            padding:0 0.5rem;
            &-tit{
                padding:0.2rem 0;
            }
            &-input{
                width: 40%;
            }
            &-con{
                padding:0.2rem  0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .switch{
                    width: 50%
                }
            }
        }
        &-input{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.5rem;
            padding-bottom:0.5rem;
            &-tit{
                padding:0.2rem 0;
            }
            &-input{
                width: 40%;
            }
        }
    }
    .switch{
        border-radius: 5rem;
        display: flex;
        justify-content: space-between;
        &-styleItem{
            background: #F7F9FA;
            color: white;
            padding: 0.15rem 0 0.2rem;
            text-align: center;
            width: 48%;
            font-weight: bolder;
            &_left{
                border-top-left-radius: 5rem;
                border-bottom-left-radius: 5rem;
            }
            &_right{
                border-top-right-radius: 5rem;
                border-bottom-right-radius: 5rem;
            }
        }
        .actived{
            background: #FFE7E9;
            color: #FF1B37;
            box-shadow:1px 1px 5px rgba(0, 0, 0, .2);
        }
    }
    .stepper{
        display: flex;
        align-items: center;
        padding:0.5rem;
        justify-content: space-between;
        &-item{
            font-size: 0.8rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between; 
        }
        &-label{
            margin-right: 0.3rem;
        }
    }
    .upload{
        padding: 1rem;
        .upload-img{
            margin-top: 0.5rem
        }
    }
</style>
<template>
    <div>
        <van-sku
            ref="sku"
            class="skuPopup"
            v-model="show"
            :sku="sku"
            :goods="goods"
            :startSaleNum="startSaleNum"
            :goods-id="goodsId"
            :price-tag="priceTag"
            :initialSku="initialSku"
            :hide-stock="sku.hide_stock" 
            :message-config="messageConfig"
            :close-on-click-overlay="true"
            reset-stepper-on-hide
            :reset-selected-sku-on-hide="true"
            :stockUnit="stockUnit"
            @sku-selected="skuSelected"
            @stepper-change="stepperChange"
            @add-cart="onAddCartClicked">
            <template slot="sku-header-price">
                <div class="good-price">
                    <span style="font-size: 1.2rem;">{{sku.price}}</span><span v-if="priceTag != ''">/{{priceTag}}</span></div>
            </template>
            <template slot="sku-body-top">
                <div class="good-title">{{goods.title}}</div>
                <div class="good-time" v-if="spu_g_attr.length > 0">
                    <div v-for="(item,index) in spu_g_attr" :key="item.arr_id">
                        <div v-if="index <= 0">
                            <div class="spu_g_attr"><span class="good-time-tip">{{item.arr_name}}</span> {{item.arr_val}}</div>    
                        </div>
                        <div></div>
                    </div>
                    <div v-if="spu_g_attr.length > 1">
                        <img  @click="skuShow = !skuShow" style="width:1.8rem;height:0.4rem" :src="moreIcon" />
                    </div>
                </div>
            </template>
            <template slot="extra-sku-group">
                <div class="extra-sku" v-if="goods_type == '2' && showMyopia">
                    <div>光度/散光</div>
                    <div>
                        <van-picker
                            ref="picker"
                            :item-height="30" 
                            :columns="columns"
                            @change="onChange" />
                    </div>
                </div>
            </template> 
            <template slot="sku-actions" slot-scope="props">
                <div class="van-sku-actions">
                    <!-- 直接触发 sku 内部事件，通过内部事件执行 onAddCartClicked 回调 -->
                    <van-button
                        square
                        size="large"
                        type="warning"
                        @click="props.skuEventBus.$emit('sku:addCart')"
                    >
                    <span v-if="type == 'edit'">确定</span>
                    <span v-else>加入购物车</span>
                    </van-button>
                </div>
            </template>
        </van-sku>
        <sku-info :skuShow="skuShow" :spu_g_attr="spu_g_attr" @handleUpdateSkuShow="handleUpdateSkuShow"></sku-info>
     </div>
</template>
<script>
import moreIcon from "@/assets/image/more.png"
import { goodsDetail ,cartAdd ,cartEdit} from "@/apis/api"
import { get_object_first_attribute } from "@/libs/utils"
import skuInfo from  "./component/skuInfo"
let myopia = {};
export default {
    name:'sku-more',
    components:{ skuInfo }, 
    props:{
        propShow:Boolean,
        type:String,
        cartId:String,
        cartId2:String,
        currCartItem:Object,
        isChooseAgain:Boolean,
        spuId:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            moreIcon:moreIcon,
            show: false,
            shoppingcar_id:"",
            shoppingcar_id2:"",
            shoppingcar_item:{},
            columns: [],
            columnsVal:[],
            goods_type:0,
            showMyopia:false,
            startSaleNum:1, // 起订数量
            sku: {
                tree: [],
                list: [],
                price: '', // 默认价格（单位元）
                stock_num: 0, // 商品总库存
                collection_id: "", // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
                none_sku: false, // 是否无规格商品
                messages: [],
                hide_stock: false // 是否隐藏剩余库存
             }, //sku信息
            goods: {
                // 商品标题
                title: '',
                // 默认商品 sku 缩略图
                picture:''
            },//商品信息
            messageConfig: { }, //留言相关配置
            goodsId:"", //商品id
            priceTag:"", //显示在加个后边的标签
            initialSku:{
                selectedNum: 1
            }, //默认选中规格值
            spu_g_attr:[], //spu普通属性
            stockUnit:"件", //剩余库存的单位
            skuShow:false, //传到skuinfo组件的show值
            res:{},
            glass_myopia_atrr:{},
            arr_gived_vals:{}, //镜片品度数散光对应的库存和价格
        }
    },
    watch:{
        //监听父组件传来的show
        propShow: {
            immediate: true,
            handler (v) {
                this.show = v;
                if(!v){
                    this.showMyopia = false
                }
            }
        },
        //监听cartId2 度数散光级别的购物车id
        cartId2(v){
            this.shoppingcar_id2 = v
        },
        //监听cartId
        cartId(v){
            this.shoppingcar_id = v
        },
        currCartItem:{
            immediate: true,
            handler (v) {
                this.shoppingcar_item = v
            }
        },
        //修改父组件的show
        show(v){
            if(v === false){
                this.$emit("handleClickPropShow")
            }
        }
    },
    methods:{
        //获取商品详情 id  initialSku 编辑购物车时选中的sku初始值
        goodsDetail(id,initialSkuValue) {
            let that = this
            return new Promise((resolve, reject) => {
                let toast = this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0,
                    loadingType: 'spinner',
                });
                let data={
                    spuId:id
                }
                goodsDetail(data).then(res=>{
                    if(res.code == 0){
                        that.res = res;
                        that.initData();
                        //初始默认选中sku start  编辑的时候
                        if(initialSkuValue){
                            that.initialSku = initialSkuValue
                        }
                    }else{
                        that.$toast(res.msg)
                    }
                    toast.clear();
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        //改变数量时
        stepperChange(value){},
        //切换规格时
        skuSelected({ skuValue, selectedSku, selectedSkuComb }){
            if(selectedSkuComb){
                this.selectedSkuComb = selectedSkuComb; //用于切换规格的时候切换库存
                if(this.goods_type == '2'){
                    this.showMyopia = true;
                    this.$nextTick(()=>{
                        this.$refs.picker.setColumnIndex(0,0)
                        this.$refs.picker.setColumnIndex(1,0)
                    })
                }
                this.glass_myopia_atrr = selectedSkuComb.glass_myopia_atrr;
                this.sku.price = selectedSkuComb.price;
                this.sku.stock_num = selectedSkuComb.stock_num;
                this.goods.picture = selectedSkuComb.image;
                this.goods.title = selectedSkuComb.name;
                this.priceTag = selectedSkuComb.unit;
                this.spu_g_attr = selectedSkuComb.g_attr;
                // 选中规格后的起订量
                this.startSaleNum = selectedSkuComb.min_order_quantity ? selectedSkuComb.min_order_quantity : 1;
                this.initialSku.selectedNum = 1; 
                
                if(this.goods_type == '2'){
                    myopia = selectedSkuComb.glass_myopia_atrr_format;
                    this.setmyopia();
                    //设置散光光度初始值
                    let tempGlass_myopia_atrr_format = selectedSkuComb.glass_myopia_atrr_format;
                    Object.keys(tempGlass_myopia_atrr_format).forEach((key,index)=>{
                        if(index == 0){
                            let temp = key+"|"+tempGlass_myopia_atrr_format[key][0]
                            let tempAll = this.glass_myopia_atrr;
                            tempAll.arr_gived_vals.map(item=>{
                                if(item.id == temp){
                                    this.arr_gived_vals = item
                                }
                            })
                        }
                    })
                    //设置光度/散光 end
                }
            }else{
                this.showMyopia = false
                this.initData();
            }
        },
        //初始化数据 skuSelect 是否是切换规格 true是 false 不是
        //如果是切换规格 就改变度数和散光的数组列表
        initData(){
            let res = this.res;
            this.goods = {
                title:res.res.spu_name,
                picture:res.res.spu_img
            }
            this.goodsId = res.res.spu_id;
            this.goods_type = res.res.goods_type;
            this.sku.price = res.res.price;
            this.sku.stock_num = res.res.inventory;
            this.sku.tree = res.res.standard;
            this.priceTag = res.res.unit;
            this.stockUnit = res.res.unit;
            //判断是否是无规格商品 start
            if(res.res.goods_type == "0"){
                this.sku.none_sku = true
                //如果是无规格的商品collection_id 要赋值为skuid
                this.sku.collection_id = res.res.skulist[0].skuId
            }else{
                this.sku.none_sku = false
            }

            if(res.res.goods_type == '2'){
                //设置光度/散光 start
                myopia = res.res.skulist[0].glass_myopia_atrr_format
                this.setmyopia();
                this.glass_myopia_atrr = res.res.skulist[0].glass_myopia_atrr
                //设置散光光度初始值
                let tempGlass_myopia_atrr_format = res.res.skulist[0].glass_myopia_atrr_format;
                Object.keys(tempGlass_myopia_atrr_format).forEach((key,index)=>{
                    if(index == 0){
                        let temp = key+"|"+tempGlass_myopia_atrr_format[key][0]
                        let tempAll = this.glass_myopia_atrr;
                        tempAll.arr_gived_vals.map(item=>{
                            if(item.id == temp){
                                this.arr_gived_vals = item
                            }
                        })
                    }
                })
                //设置光度/散光 end
            }
            //拼接sku.list start
            let skuList =  res.res.skulist;
            let skuTemp = [];
            skuList.map(item=>{
                if(res.res.goods_type == "2"){
                    //镜片品
                    let price = 0;
                    let stock_num = 0;
                    let tempGlass_myopia_atrr_format = item.glass_myopia_atrr_format;
                    Object.keys(tempGlass_myopia_atrr_format).forEach((key,index)=>{
                        if(index == 0){
                            let temp = key+"|"+tempGlass_myopia_atrr_format[key][0]
                            let tempAll = item.glass_myopia_atrr;
                            tempAll.arr_gived_vals.map(item=>{
                                if(item.id == temp){
                                    price = item.price;
                                    stock_num = item.inventory
                                }
                            })
                        }
                    })
                    let skuItem = {
                        id:item.skuId,
                        name:item.name,
                        image:item.image,
                        unit:item.unit,
                        g_attr:item.g_atrr,
                        price:price,
                        sku_atrr:item.sku_atrr,
                        sku_measure_id:item.sku_measure_id,
                        stock_num:stock_num,
                        standard:res.res.standard,
                        glass_myopia_atrr_format:item.glass_myopia_atrr_format,
                        glass_myopia_atrr:item.glass_myopia_atrr
                    }
                    skuTemp.push(skuItem)
                }else{
                    let skuItem = {
                        id:item.skuId,
                        name:item.name,
                        image:item.image,
                        unit:item.unit,
                        g_attr:item.g_atrr,
                        price:item.price,
                        sku_atrr:item.sku_atrr,
                        sku_measure_id:item.sku_measure_id,
                        stock_num:item.stock_num,
                        standard:res.res.standard,
                        min_order_quantity:item.min_order_quantity
                    }
                    skuTemp.push(skuItem)
                }
            })
            skuTemp.map(item=>{
                item.sku_atrr.map(childItem=>{
                    item[childItem.arr_id] = childItem.arr_val_id;
                })
            })
            this.sku.list = skuTemp;
            //设置spu普通属性 start
            this.spu_g_attr = res.res.g_atrr;
            // 初始化起订量
            if(res.res.goods_type === '0'){
                this.setStartNum()
            }else{
                this.startSaleNum = 1
                this.initialSku.selectedNum = 1 
            }
        },
        //设置起订数量
        setStartNum(){
            // 如果是单一品 就要在初始化的时候设置起订数量
            if(this.res.res.skulist.length > 0 && this.res.res.skulist[0].min_order_quantity){
                this.startSaleNum = this.res.res.skulist[0].min_order_quantity
                this.initialSku.selectedNum = this.res.res.skulist[0].min_order_quantity
            }
        },
        //异步获取的光度散光
        setmyopia(){
            this.columns = [
                {
                    values: Object.keys(myopia),
                    className: 'guang'
                },
                {
                    values: myopia[get_object_first_attribute(myopia)],
                    className: 'san',
                }
            ]
        },
        //添加购物车方法
        onAddCartClicked(skuData){
            if(this.type == 'edit'){
                let selectAtrrs = [];
                if(skuData.selectedSkuComb.sku_atrr){ //如果是单规格的是没有这个属性的
                    skuData.selectedSkuComb.sku_atrr.map(item=>{
                        let atrrsItem = { 
                            arr_id: item.arr_id,
                            val: item.arr_val_id,
                            type: "1"
                        }
                        selectAtrrs.push(atrrsItem)
                    })
                }
                //如果是眼镜片品  因为外层没有购物车id 所以取选中的id
                if(this.goods_type == '2'){
                    let shoppingcar_item = this.shoppingcar_item;
                    shoppingcar_item.glass_myopia_atrr.attr_val.map(item=>{
                        if(item.id == this.arr_gived_vals.id){
                            this.shoppingcar_id = item.shoppingcar_id;
                        }
                    })
                }
                let data = {
                    spuId:skuData.goodsId,
                    skuId:skuData.selectedSkuComb.id,
                    sku_measure_id:this.sku.none_sku?this.sku.list[0].sku_measure_id:skuData.selectedSkuComb.sku_measure_id,
                    quantity:skuData.selectedNum,
                    shoppingId:this.shoppingcar_id,
                    attrs:selectAtrrs
                }
                if(this.goods_type == '2'){ //如果是镜片品需要将选中的镜片品id传去后台
                    data.myopia = this.arr_gived_vals.id
                }
                if(this.shoppingcar_id == ""){//如果没有的话 那就是添加
                    cartAdd(data).then(res=>{
                        if(res.code == 0){
                            this.$toast("成功加入")
                            // this.show = !this.show;
                            this.$emit("getCartList")
                            //修改购物车图标组件中的个数
                            this.$store.dispatch('setCartCount')
                        }else{
                            this.$toast(res.msg)
                        }
                    })
                }else{
                    cartEdit(data).then(res=>{
                        if(res.code == 0){
                            this.$toast("修改成功")
                            this.$emit("getCartList")
                            //  this.show = !this.show;
                        }else{
                            this.$toast(res.msg)
                        }
                    })
                }
            }else{
                let selectAtrrs = [];
                if(skuData.selectedSkuComb.sku_atrr){ //如果是单规格的是没有这个属性的
                    skuData.selectedSkuComb.sku_atrr.map(item=>{
                        let atrrsItem = { 
                            arr_id: item.arr_id,
                            val: item.arr_val_id,
                            type: "1"
                        }
                        selectAtrrs.push(atrrsItem)
                    })
                }
                let data = {
                    spuId:skuData.goodsId,
                    skuId:skuData.selectedSkuComb.id,
                    sku_measure_id:this.sku.none_sku?this.sku.list[0].sku_measure_id:skuData.selectedSkuComb.sku_measure_id,
                    quantity:skuData.selectedNum,
                    attrs:selectAtrrs
                }
                if(this.goods_type == '2'){ //如果是镜片品需要将选中的镜片品id传去后台
                    data.myopia = this.arr_gived_vals.id
                }
                cartAdd(data).then(res=>{
                    if(res.code == 0){
                        this.$toast("成功加入")
                        // this.show = !this.show;
                        //如果是重选 要删除一下旧商品
                        if(this.isChooseAgain){
                            if(this.goods_type == '2'){
                                if(this.shoppingcar_id2){
                                    this.$emit("invalidOne",[this.shoppingcar_id2])
                                }else{
                                    let shoppingcar_idArr = []
                                    this.shoppingcar_item.glass_myopia_atrr.attr_val.map(childItem=>{
                                        shoppingcar_idArr.push(childItem.shoppingcar_id)
                                    })
                                    this.$emit("invalidOne",shoppingcar_idArr)
                                }
                            }else{
                                this.$emit("invalidOne",[this.shoppingcar_id])
                            }
                        }
                        this.$emit("handleGetCartList")
                        //修改购物车图标组件中的个数
                        this.$store.dispatch('setCartCount')
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }
            
        },
        //选择光度和散光
        onChange(picker,values) {
            this.shoppingcar_id = ""
            picker.setColumnValues(1, myopia[values[0]]);
            this.columnsVal = picker.getValues()
            this.getArrGivedVals(this.columnsVal);
        },
        //根据光度散光获取价格库存等信息
        getArrGivedVals(values){
            let tempSkuComb = this.selectedSkuComb;
            let key = values[0] + '|' + values[1]
            let arr_gived_vals = this.glass_myopia_atrr.arr_gived_vals;
            arr_gived_vals.map(item=>{
                if(item.id == key){
                    this.arr_gived_vals = item;
                    this.sku.price = item.price;
                    tempSkuComb.stock_num = item.inventory;
                }
            })
        },
        //监听skuinfo组件的show值修改skuShow
        handleUpdateSkuShow(v){
            this.skuShow = v
        }
    }
}
</script>