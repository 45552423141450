<style lang="less">
    .van-sku-container{
        max-height: 95%;
    }
    .van-sku-messages{
        padding-bottom:0.5rem
    }
    .skuPopup .good-title{
        padding: 1rem;
        color: #3F4562;
        font-weight: bold;
    }
    .skuPopup .good-price{
        color: red;
        font-weight: bold;
        margin-top: 0;
    }
    .skuPopup .van-sku-header__goods-info{
        justify-content: flex-start;
    }
    .skuPopup .extra-sku{
        font-size: 0.875rem;
        padding: 1rem;
    }
    .skuPopup .van-picker__columns{
        font-size: 0.6rem;
    }
    .skuPopup .good-time{
        padding:0 1rem 0.5rem;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .skuPopup .good-time::after{
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        border-bottom: 0.0625rem solid #ebedf0;
        -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
        transform: scale(.5);
    }
    .skuPopup .good-time-tip{
        padding:0 0.2rem;
        color: #fff;
        background:linear-gradient(90deg,rgba(255,87,43,1) 0%,rgba(255,0,23,1) 100%);
        border-radius:2px;
        margin-right: 0.3rem;
    }
    .spu_g_attr{
        margin: 0.5rem 0;
        line-height: 1.2rem;
    }
    //特殊镜片品
    .extra-sku_special{
        padding:0.5rem;
        display: flex;
        justify-content: space-between;
        .van-cell{
            padding:0;
            border: .08rem solid #D8D8D8;
            border-radius: .3rem;
        }
        &-item{
            font-size: 0.7rem;
            border-radius: 5px;
            width: 49%;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
            input::-webkit-input-placeholder{
                font-size: 0.7rem
            }
            input::-moz-placeholder{
                font-size: 0.7rem
            }
            input::-ms-input-placeholder{
                font-size: 0.7rem
            }
        }
        &-tit{
            font-size: .88rem;
            text-align: center;
            padding: 0.2rem 0;
        }
        &-switch{
            padding:0 0.5rem;
            &-tit{
                padding:0.2rem 0;
            }
            &-input{
                width: 40%;
            }
            &-con{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .switch{
                    width: 50%
                }
            }
        }
        &-input{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem;
            &-tit{
                padding:0.2rem 0;
            }
            &-input{
                width: 40%;
            }
        }
    }
    .switch{
        border-radius: 5rem;
        display: flex;
        justify-content: space-between;
        &-styleItem{
            background: #F7F9FA;
            color: white;
            padding: 0.15rem 0 0.2rem;
            text-align: center;
            width: 48%;
            font-weight: bolder;
            &_left{
                border-top-left-radius: 5rem;
                border-bottom-left-radius: 5rem;
            }
            &_right{
                border-top-right-radius: 5rem;
                border-bottom-right-radius: 5rem;
            }
        }
        .actived{
            background: #FFE7E9;
            color: #FF1B37;
            box-shadow:1px 1px 5px rgba(0, 0, 0, .2);
        }
    }
    .stepper{
        display: flex;
        align-items: center;
        padding: 0.5rem;
        justify-content: space-between;
        &-item{
            font-size: 0.8rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between; 
        }
        &-label{
            margin-right: 0.3rem;
        }
    }
    .upload{
        padding: 1rem;
        .upload-img{
            margin-top: 0.5rem
        }
    }
</style>
<template>
    <div>
        <van-sku
            class="skuPopup"
            v-model="show"
            :sku="sku"
            :goods="goods"
            :goods-id="goodsId"
            :price-tag="priceTag"
            :hide-stock="true"
            :initial-sku="initialSku"
            :message-config="messageConfig"
            :close-on-click-overlay="true"
            :reset-stepper-on-hide="true"
            :reset-selected-sku-on-hide="true"
            :stockUnit="stockUnit"
            @sku-selected="skuSelected"
            @stepper-change="stepperChange"
            @add-cart="onAddCartClicked">
            <template slot="sku-header-price">
                <div class="good-price">
                    <div>右眼:<span style="font-size: 1.2rem;">{{sku.rightPrice}}</span><span v-if="priceTag != ''">/{{priceTag}}</span></div>
                    <div>左眼:<span style="font-size: 1.2rem;">{{sku.leftPrice}}</span><span v-if="priceTag != ''">/{{priceTag}}</span></div>
                </div>
            </template>
            <template slot="sku-body-top">
                <div class="good-title">{{goods.title}}</div>
                <div class="good-time" v-if="spu_g_attr.length > 0">
                    <div v-for="(item,index) in spu_g_attr" :key="item.arr_id">
                        <div v-if="index <= 0">
                            <div class="spu_g_attr"><span class="good-time-tip">{{item.arr_name}}</span> {{item.arr_val}}</div>    
                        </div>
                        <div></div>
                    </div>
                    <div v-if="spu_g_attr.length > 1">
                        <img  @click="skuShow = !skuShow" style="width:1.8rem;height:0.4rem" :src="moreIcon" />
                    </div>
                </div>
            </template>
            <template slot="extra-sku-group">
                <div class="extra-sku_special">
                    <div class="extra-sku_special-item">
                        <div class="extra-sku_special-tit">右眼</div>
                        <div class="extra-sku_special-switch">
                            <div class="extra-sku_special-switch-tit">
                                光度<span style="color:red">*</span>
                            </div>
                            <div class="extra-sku_special-switch-con">
                                <div class="switch">
                                    <div 
                                        @click="togglePlusMinus('rightGuangPlusMinus','+')"
                                        class="switch-styleItem switch-styleItem_left" 
                                        :class="specialFormData.rightGuangPlusMinus === '+'?'actived':''">
                                        <div>+</div>
                                    </div>
                                    <div 
                                        @click="togglePlusMinus('rightGuangPlusMinus','-')"
                                        class="switch-styleItem switch-styleItem_right" 
                                        :class="specialFormData.rightGuangPlusMinus === '-'?'actived':''">
                                        <div>-</div>
                                    </div>
                                </div>
                                <div class="extra-sku_special-switch-input">
                                    <van-field 
                                        placeholder="例如:1.00"
                                        type="number"
                                        @blur="(e)=>checkGuangSanXia(e,'rightGuang')" 
                                        :error="specialFormData.rightGuang.error" 
                                        input-align="center" 
                                        v-model="specialFormData.rightGuang.value" />
                                </div>
                            </div>
                        </div>
                        <div class="extra-sku_special-switch">
                            <div class="extra-sku_special-switch-tit">
                                散光<span style="color:red">*</span>
                            </div>
                            <div class="extra-sku_special-switch-con">
                                <div class="switch">
                                    <div 
                                        @click="togglePlusMinus('rightSanPlusMinus','+')"
                                        class="switch-styleItem switch-styleItem_left"
                                        :class="specialFormData.rightSanPlusMinus === '+'?'actived':''">
                                        <div>+</div>
                                    </div>
                                    <div 
                                        @click="togglePlusMinus('rightSanPlusMinus','-')"
                                        class="switch-styleItem switch-styleItem_right"
                                        :class="specialFormData.rightSanPlusMinus === '-'?'actived':''">
                                        <div>-</div>
                                    </div>
                                </div>
                                <div class="extra-sku_special-switch-input">
                                    <van-field 
                                        placeholder="例如:1.00"
                                        type="number" 
                                        @blur="(e)=>checkGuangSanXia(e,'rightSan')" 
                                        input-align="center" 
                                        :error="specialFormData.rightSan.error" 
                                        v-model="specialFormData.rightSan.value" />
                                </div>
                            </div>
                        </div>
                        <div class="extra-sku_special-input">
                            <div class="extra-sku_special-input-tit">
                                下加光ADD<span style="color:red">*</span>
                            </div>
                            <div class="extra-sku_special-input-input">
                                 <van-field 
                                    placeholder="例如:1.25"
                                    @blur="(e)=>checkGuangSanXia(e,'rightXia')" 
                                    type="number" 
                                    input-align="center" 
                                    :error="specialFormData.rightXia.error" 
                                    v-model="specialFormData.rightXia.value" />
                            </div>
                        </div>
                        <div class="extra-sku_special-input">
                            <div class="extra-sku_special-input-tit">
                                散光轴位
                            </div>
                            <div class="extra-sku_special-input-input">
                                 <van-field
                                    placeholder="例如:10"
                                    type="number" 
                                    input-align="center" 
                                    :error="specialFormData.rightSanZhou.error" 
                                    v-model="specialFormData.rightSanZhou.value" />
                            </div>
                        </div>
                        <div class="extra-sku_special-input">
                            <div class="extra-sku_special-input-tit">
                                瞳距(mm)
                            </div>
                            <div class="extra-sku_special-input-input">
                                <van-field 
                                    placeholder="例如:10"
                                    type="number" 
                                    input-align="center" 
                                    :error="specialFormData.rightTong.error" 
                                    v-model="specialFormData.rightTong.value" />
                            </div>
                        </div>
                        <div class="stepper">
                            <div class="stepper-item">
                                <div class="stepper-label">订购数量</div>
                                <van-stepper
                                    @change="changeRightCount"
                                    button-size="1.2rem" 
                                    min="0" 
                                    :value="specialFormData.rightCount" 
                                    integer />
                            </div>
                        </div>
                    </div>
                    <div class="extra-sku_special-item">
                        <div class="extra-sku_special-tit">左眼</div>
                        <div class="extra-sku_special-switch">
                            <div class="extra-sku_special-switch-tit">
                                光度<span style="color:red">*</span>
                            </div>
                            <div class="extra-sku_special-switch-con">
                                <div class="switch">
                                    <div
                                        @click="togglePlusMinus('leftGuangPlusMinus','+')"
                                        class="switch-styleItem  switch-styleItem_left"
                                        :class="specialFormData.leftGuangPlusMinus === '+'?'actived':''">
                                        <div>+</div>
                                    </div>
                                    <div 
                                        @click="togglePlusMinus('leftGuangPlusMinus','-')"
                                        class="switch-styleItem  switch-styleItem_right"
                                        :class="specialFormData.leftGuangPlusMinus === '-'?'actived':''">
                                        <div>-</div>
                                    </div>
                                </div>
                                <div class="extra-sku_special-switch-input">
                                    <van-field 
                                        placeholder="例如:1.00"
                                        type="number" 
                                        @blur="(e)=>checkGuangSanXia(e,'leftGuang')" 
                                        input-align="center" 
                                        :error="specialFormData.leftGuang.error" 
                                        v-model="specialFormData.leftGuang.value" />
                                </div>
                            </div>
                        </div>
                        <div class="extra-sku_special-switch">
                            <div class="extra-sku_special-switch-tit">
                                散光<span style="color:red">*</span>
                            </div>
                            <div class="extra-sku_special-switch-con">
                                <div class="switch">
                                    <div
                                        @click="togglePlusMinus('leftSanPlusMinus','+')"
                                        class="switch-styleItem  switch-styleItem_left"
                                        :class="specialFormData.leftSanPlusMinus === '+'?'actived':''">
                                        <div>+</div>
                                    </div>
                                    <div
                                        @click="togglePlusMinus('leftSanPlusMinus','-')"
                                        class="switch-styleItem  switch-styleItem_right"
                                        :class="specialFormData.leftSanPlusMinus === '-'?'actived':''">
                                        <div>-</div>
                                    </div>
                                </div>
                                <div class="extra-sku_special-switch-input">
                                    <van-field 
                                        placeholder="例如:1.00"
                                        type="number"
                                        @blur="(e)=>checkGuangSanXia(e,'leftSan')"
                                        input-align="center" 
                                        :error="specialFormData.leftSan.error" 
                                        v-model="specialFormData.leftSan.value" />
                                </div>
                            </div>
                        </div>
                        <div class="extra-sku_special-input">
                            <div class="extra-sku_special-input-tit">
                                下加光ADD<span style="color:red">*</span>
                            </div>
                            <div class="extra-sku_special-input-input">
                                <van-field 
                                    placeholder="例如:1.25"
                                    type="number"
                                    @blur="(e)=>checkGuangSanXia(e,'leftXia')"
                                    input-align="center" 
                                    :error="specialFormData.leftXia.error" 
                                    v-model="specialFormData.leftXia.value" />
                            </div>
                        </div>
                        <div class="extra-sku_special-input">
                            <div class="extra-sku_special-input-tit">
                                散光轴位
                            </div>
                            <div class="extra-sku_special-input-input">
                                <van-field 
                                placeholder="例如:10"
                                    type="number" 
                                    input-align="center" 
                                    :error="specialFormData.leftSanZhou.error" 
                                    v-model="specialFormData.leftSanZhou.value" />
                            </div>
                        </div>
                        <div class="extra-sku_special-input">
                            <div class="extra-sku_special-input-tit">
                                瞳距(mm)
                            </div>
                            <div class="extra-sku_special-input-input">
                                <van-field 
                                placeholder="例如:10"
                                    type="number" 
                                    input-align="center" 
                                    :error="specialFormData.leftTong.error"
                                    v-model="specialFormData.leftTong.value" />
                            </div>
                        </div>
                        <div class="stepper">
                            <div class="stepper-item">
                                <div class="stepper-label">订购数量</div>
                                <van-stepper
                                    @change="changeLeftCount"
                                    button-size="1.2rem" 
                                    min="0" 
                                    :value="specialFormData.leftCount" 
                                    integer />
                            </div>
                        </div>
                    </div>
                </div>
            </template> 
            <template slot="sku-stepper">
                <div></div>
                <!-- <div class="upload">
                    <div>图片附件</div>
                    <div class="upload-img">
                        <van-uploader v-model="specialFormData.imgList" max-count="1" :after-read="afterRead" />
                    </div>
                </div> -->
            </template>
            <template slot="sku-actions" slot-scope="props">
                <div class="van-sku-actions">
                    <!-- 直接触发 sku 内部事件，通过内部事件执行 onAddCartClicked 回调 -->
                    <van-button
                        square
                        size="large"
                        type="warning"
                        @click="props.skuEventBus.$emit('sku:addCart')"
                    >
                    <span v-if="type == 'edit'">确定</span>
                    <span v-else>加入购物车</span>
                    </van-button>
                </div>
            </template>
        </van-sku>
        <sku-info :skuShow="skuShow" :spu_g_attr="spu_g_attr" @handleUpdateSkuShow="handleUpdateSkuShow"></sku-info>
     </div>
</template>
<script>
import moreIcon from "@/assets/image/more.png"
import { goodsDetail ,cartAdd2 ,cartEdit2} from "@/apis/api"
import { get_object_first_attribute } from "@/libs/utils"
import skuInfo from  "./component/skuInfo"
export default {
    name:'sku-more',
    components:{ skuInfo }, 
    props:{
        propShow:Boolean,
        type:String,
        cartId2:String,
        currCartItem:Object,
        isChooseAgain:Boolean,
        spuId:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            moreIcon:moreIcon,
            show: false,
            shoppingcar_id2:[], // 光度散光购物车id
            shoppingcar_item:{},
            goods_type:3,
            skuList:[],
            sku: {
                tree: [],
                list: [],
                leftPrice:'',
                rightPrice:'',
                price: '', // 默认价格（单位元）
                stock_num: 0, // 商品总库存
                collection_id: "", // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
                none_sku: false, // 是否无规格商品
                messages: []
             }, //sku信息
            goods: {
                // 商品标题
                title: '',
                // 默认商品 sku 缩略图
                picture:''
             },//商品信息
            messageConfig: { }, //留言相关配置
            goodsId:"", //商品id
            priceTag:"", //显示在加个后边的标签
            initialSku:{}, //默认选中规格值
            spu_g_attr:[], //spu普通属性
            stockUnit:"件", //剩余库存的单位
            skuShow:false, //传到skuinfo组件的show值
            res:{},
            glass_myopia_atrr:{},
            glass_myopia_atrr_format:{},//所有光度散光数据
            specialFormData:{
                rightGuangPlusMinus:'-',
                leftGuangPlusMinus:'+',
                rightSanPlusMinus:'-',
                leftSanPlusMinus:'+',
                rightGuang:{
                    value:'',
                    error:false
                },
                leftGuang:{
                    value:'',
                    error:false
                },
                rightSan:{
                    value:'',
                    error:false
                },
                leftSan:{
                    value:'',
                    error:false
                },
                rightSanZhou:{
                    value:'',
                    error:false
                },
                leftSanZhou:{
                    value:'',
                    error:false
                },
                rightTong:{
                    value:'',
                    error:false
                },
                leftTong:{
                    value:'',
                    error:false
                },
                rightXia:{
                    value:'',
                    error:false
                },
                leftXia:{
                    value:'',
                    error:false
                },
                rightCount:0,
                leftCount:0
            },//特殊定制镜片品提交信息
        }
    },
    watch:{
        //监听父组件传来的show
        propShow: {
            immediate: true,
            handler (v) {
                this.show = v;
                if(!v){
                    this.initSpecialFormData()
                }
            }
        },
        //监听cartId2
        cartId2(v){
            if(v.left_or_right_eyes == '2'){
                this.shoppingcar_id2[0] = v
            }
            if(v.left_or_right_eyes == '1'){
                this.shoppingcar_id2[1] = v
            }
        },
        currCartItem:{
            immediate: true,
            handler (v) {
                this.shoppingcar_item = v
            }
        },
        //修改父组件的show
        show(v){
            if(v === false){
                this.$emit("handleClickPropShow")
            }
        }
    },
    methods:{
        changeLeftCount(value){
            this.specialFormData.leftCount = value
            this.computePrice()
        },
        changeRightCount(value){
            this.specialFormData.rightCount = value
            this.computePrice()
        },
        // 初始化数据
        initSpecialFormData (){
            this.specialFormData = {
                rightGuangPlusMinus:'-',
                leftGuangPlusMinus:'-',
                rightSanPlusMinus:'-',
                leftSanPlusMinus:'-',
                rightGuang:{
                    value:'',
                    error:false
                },
                leftGuang:{
                    value:'',
                    error:false
                },
                rightSan:{
                    value:'',
                    error:false
                },
                leftSan:{
                    value:'',
                    error:false
                },
                rightSanZhou:{
                    value:'',
                    error:false
                },
                leftSanZhou:{
                    value:'',
                    error:false
                },
                rightTong:{
                    value:'',
                    error:false
                },
                leftTong:{
                    value:'',
                    error:false
                },
                rightXia:{
                    value:'',
                    error:false
                },
                leftXia:{
                    value:'',
                    error:false
                },
                rightCount:0,
                leftCount:0,
                imgList:[], //上传处方图片信息
            }
        },
        // 验证保留两位小数
        twoDecimalPlacesCheck(value){
            const req = /^(\d+|\d+\.\d{1,2})$/
            if(!req.test(value)){
                return false
            }else{
                return true
            }
        },
        // 验证光度 散光和下加光是否符合
        checkGuangSanXia(e,name){
            if(!this.selectedSkuComb){
                this.initSpecialFormData()
                this.$toast("请先选择规格")
                return false
            }
            let value = Number(e.target.value).toFixed(2);
            if(this.twoDecimalPlacesCheck(value) && (value % 0.25 == 0)){
                // 如果是光度需要验证是否有这个光度
                if(name == 'rightGuang' || name == 'leftGuang'){
                    this.clearSan(name)
                    let plusminus = name =='rightGuang'?this.specialFormData['rightGuangPlusMinus']:this.specialFormData['leftGuangPlusMinus']
                    if(this.isHaveGuang(value,plusminus)){
                        this.computePrice()
                        this.specialFormData[name].error = false
                    }else{
                        this.specialFormData[name].error = true
                    }
                }
                // 如果是散光需要验证是否有这个散光
                else if(name == 'rightSan' || name == 'leftSan'){
                    if(!this.isGuang(name)){
                        this.$toast("请先输入光度")
                        return false
                    }
                    let plusminus = name =='rightSan'?this.specialFormData['rightSanPlusMinus']:this.specialFormData['leftSanPlusMinus']
                    if(this.isHaveSan(value,plusminus,name)){
                        this.computePrice()
                        this.specialFormData[name].error = false
                    }else{
                        this.specialFormData[name].error = true
                    }
                }else{
                    this.specialFormData[name].error = false  
                }
            }else{
                this.specialFormData[name].error = true
            }
        },
        // 切换正负
        togglePlusMinus(name,value){
            if(!this.selectedSkuComb){
                this.initSpecialFormData()
                this.$toast("请先选择规格")
                return false
            }
            this.specialFormData[name] = value
            let plusminus = value
            if(name == 'rightGuangPlusMinus'){
                if(this.specialFormData.rightGuang.value == ''){
                    return false
                }
                if(this.isHaveGuang(this.specialFormData.rightGuang.value,plusminus)){
                    this.computePrice()
                    this.specialFormData['rightGuang'].error = false
                }else{
                    this.specialFormData['rightGuang'].error = true
                }
            }
            if(name == 'leftGuangPlusMinus'){
                if(this.specialFormData.leftGuang.value == ''){
                    return false
                }
                if(this.isHaveGuang(this.specialFormData.leftGuang.value,plusminus)){
                    this.computePrice()
                    this.specialFormData['leftGuang'].error = false
                }else{
                    this.specialFormData['leftGuang'].error = true
                }
            }
            if(name == 'rightSanPlusMinus'){
                if(!this.isGuang('rightSan')){
                    this.$toast("请先输入光度")
                    return false
                }
                if(this.specialFormData.rightSan.value == ''){
                    return false
                }
                if(this.isHaveSan(this.specialFormData.rightSan.value,plusminus,'rightSan')){
                    this.computePrice()
                    this.specialFormData['rightSan'].error = false
                }else{
                    this.specialFormData['rightSan'].error = true
                }
            }
            if(name == 'leftSanPlusMinus'){
                if(!this.isGuang('leftSan')){
                    this.$toast("请先输入光度")
                    return false
                }
                if(this.specialFormData.leftSan.value == ''){
                    return false
                }
                if(this.isHaveSan(this.specialFormData.leftSan.value,plusminus,'leftSan')){
                    this.computePrice()
                    this.specialFormData['leftSan'].error = false
                }else{
                    this.specialFormData['leftSan'].error = true
                }
            }
        },
        // 选择散光之前 判断是否已经选择了光度
        isGuang (name){
            if(name == 'rightSan'){
                if(this.specialFormData.rightGuang.value == ''){
                    this.specialFormData.rightSan.value = ''
                    return false
                }else{
                    return true
                }
            }
            if(name = 'leftSan'){
                if(this.specialFormData.leftGuang.value == ''){
                    this.specialFormData.leftSan.value = ''
                    return false
                }else{
                    return true
                }
            }
            return true
        },
        // 选择光度的时候 需要清空散光
        clearSan(name){
            if(name == 'rightGuang'){
                this.specialFormData.rightSan.value = ''
                this.specialFormData.rightSan.error = false
            }
            if(name == 'leftGuang'){
                this.specialFormData.leftSan.value = ''
                this.specialFormData.leftSan.error = false
            }
        },
        // 验证光度选择是否是已有的
        isHaveGuang(value,plusminus){
            const valueTemp = Number(value).toFixed(2)
            let guangList = Object.keys(this.glass_myopia_atrr_format)
            if(valueTemp == 0.00){
                plusminus = ''
            }
            if(guangList.includes(plusminus+valueTemp)){
                return true
            }else{
                this.$toast("暂时没有该光度镜片，请拍照下单或与客服联系")
                return false
            }
        },
        // 验证选择的散度是都是已有的
        isHaveSan(value,plusminus,name){
            const valueTemp = Number(value).toFixed(2)
            let glass_myopia_atrr_format = this.glass_myopia_atrr_format
            if(valueTemp == 0.00){
                plusminus = ''
            }
            if(name == 'rightSan'){
                let key = 0.00;
                let guangTemp = Number(this.specialFormData.rightGuang.value).toFixed(2)
                if(guangTemp == 0.00){
                    key = Number(this.specialFormData.rightGuang.value).toFixed(2);
                }else{
                    key = this.specialFormData.rightGuangPlusMinus+Number(this.specialFormData.rightGuang.value).toFixed(2);
                }
                let sanList = glass_myopia_atrr_format[key]
                if(sanList && sanList.includes(plusminus+valueTemp)){
                    return true
                }else{
                    this.$toast("暂时没有该散光镜片，请拍照下单或与客服联系")
                    return false
                }
            }else if(name == 'leftSan'){
                let key = 0.00;
                let guangTemp = Number(this.specialFormData.leftGuang.value).toFixed(2)
                if(guangTemp == 0.00){
                    key = Number(this.specialFormData.leftGuang.value).toFixed(2);
                }else{
                    key = this.specialFormData.leftGuangPlusMinus+Number(this.specialFormData.leftGuang.value).toFixed(2);
                }
                let sanList = glass_myopia_atrr_format[key]
                if(sanList && sanList.includes(plusminus+valueTemp)){
                    return true
                }else{
                    this.$toast("暂时没有该散光镜片，请拍照下单或与客服联系")
                    return false
                }
            }
        },
        // 根据光度散度不同切换价格
        computePrice (){
            let skuId = this.selectedSkuComb.id;
            let rightGuang = this.formatGuangSan(this.specialFormData.rightGuangPlusMinus,this.specialFormData.rightGuang.value);
            let rightSan = this.formatGuangSan(this.specialFormData.rightSanPlusMinus,this.specialFormData.rightSan.value);
            let leftGuang = this.formatGuangSan(this.specialFormData.leftGuangPlusMinus,this.specialFormData.leftGuang.value);
            let leftSan = this.formatGuangSan(this.specialFormData.leftSanPlusMinus,this.specialFormData.leftSan.value);
            let skuItem = {}
            this.skuList.map(item =>{
                if(item.skuId == skuId){
                    skuItem = item
                }
            })
            let skuItemArrGivedVals = skuItem.glass_myopia_atrr.arr_gived_vals
            let rightPrice = '';
            let leftPrice = '';
            if(this.specialFormData.rightGuang.value && this.specialFormData.rightSan.value){
                skuItemArrGivedVals.map(item =>{
                    if(item.id == Number(rightGuang).toFixed(2) + '|' + Number(rightSan).toFixed(2)){
                        rightPrice = item.price
                    }
                })
                this.sku.rightPrice = rightPrice
            }
            if(this.specialFormData.leftGuang.value && this.specialFormData.leftSan.value){
                skuItemArrGivedVals.map(item =>{
                    if(item.id == Number(leftGuang).toFixed(2) + '|' + Number(leftSan).toFixed(2)){
                        leftPrice = item.price
                    }
                })
                this.sku.leftPrice = leftPrice
            }
        },
        // 整理光度散度的格式 如果是0.00 就不加正负号
        formatGuangSan(plusminus,val){
            if(val == '0.00'){
                val = val
            }else{
                val = plusminus + val
            }
            return val
        },
        //获取商品详情 id  initialSku 编辑购物车时选中的sku初始值
        goodsDetail(id,initialSkuValue){
            return new Promise((resolve, reject) => {
                let toast = this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0,
                    loadingType: 'spinner',
                });
                let data={
                    spuId:id
                }
                goodsDetail(data).then(res=>{
                    if(res.code == 0){
                        this.res = res;
                        this.initData();
                        //初始默认选中sku start  编辑的时候
                        if(initialSkuValue){
                            this.initialSku = initialSkuValue
                        }
                        //初始默认选中sku end
                    }else{
                        this.$toast(res.msg)
                    }
                    toast.clear();
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        //改变数量时
        stepperChange(value){},
        //切换规格时
        skuSelected({ skuValue, selectedSku, selectedSkuComb }){
            this.initSpecialFormData()
            if(selectedSkuComb){
                this.selectedSkuComb = selectedSkuComb;
                // this.sku.price = selectedSkuComb.price;
                this.glass_myopia_atrr = selectedSkuComb.glass_myopia_atrr;
                this.sku.stock_num = selectedSkuComb.stock_num;
                this.goods.picture = selectedSkuComb.image;
                this.goods.title = selectedSkuComb.name;
                this.priceTag = selectedSkuComb.unit;
                this.spu_g_attr = selectedSkuComb.g_attr;
                this.glass_myopia_atrr_format = selectedSkuComb.glass_myopia_atrr_format
                //设置散光光度初始值
                let tempGlass_myopia_atrr_format = selectedSkuComb.glass_myopia_atrr_format;
                Object.keys(tempGlass_myopia_atrr_format).forEach((key,index)=>{
                    if(index == 0){
                        let temp = key+"|"+tempGlass_myopia_atrr_format[key][0]
                        let tempAll = this.glass_myopia_atrr;
                        tempAll.arr_gived_vals.map(item=>{
                            if(item.id == temp){
                                this.arr_gived_vals = item
                            }
                        })
                    }
                })
                //设置光度/散光 end
                // this.computePrice()
            }else{
                this.selectedSkuComb = ""
                this.initData();
            }
        },
        //初始化数据 skuSelect 是否是切换规格 true是 false 不是
        //如果是切换规格 就改变度数和散光的数组列表
        initData(){
            let res = this.res;
            this.goods = {
                title:res.res.spu_name,
                picture:res.res.spu_img
            }
            this.goodsId = res.res.spu_id;
            this.goods_type = res.res.goods_type;
            this.sku.price = res.res.price;
            this.sku.rightPrice = res.res.price;
            this.sku.leftPrice = res.res.price;
            this.sku.stock_num = res.res.inventory;
            this.sku.tree = res.res.standard;
            this.priceTag = res.res.unit;
            this.stockUnit = res.res.unit;
            this.skuList = res.res.skulist;
            this.glass_myopia_atrr_format = res.res.skulist[0].glass_myopia_atrr_format
            this.glass_myopia_atrr = res.res.skulist[0].glass_myopia_atrr
            //判断是否是无规格商品 start
            if(res.res.goods_type == "0"){
                this.sku.none_sku = true
                //如果是无规格的商品collection_id 要赋值为skuid
                this.sku.collection_id = res.res.skulist[0].skuId
            }else{
                this.sku.none_sku = false
            }
            //判断是否是无规格商品 end
            //设置散光光度初始值
            let tempGlass_myopia_atrr_format = res.res.skulist[0].glass_myopia_atrr_format;
            Object.keys(tempGlass_myopia_atrr_format).forEach((key,index)=>{
                if(index == 0){
                    let temp = key+"|"+tempGlass_myopia_atrr_format[key][0]
                    let tempAll = this.glass_myopia_atrr;
                    tempAll.arr_gived_vals.map(item=>{
                        if(item.id == temp){
                            this.arr_gived_vals = item
                        }
                    })
                }
            })
            //设置光度/散光 end

            //设置spu普通属性 end
            //拼接sku.list start
            let skuList =  res.res.skulist;
            let skuTemp = [];
            skuList.map(item=>{
                let skuItem = {
                    id:item.skuId,
                    name:item.name,
                    image:item.image,
                    unit:item.unit,
                    g_attr:item.g_atrr,
                    // price:this.sku.price,
                    sku_atrr:item.sku_atrr,
                    sku_measure_id:item.sku_measure_id,
                    stock_num:999,
                    standard:res.res.standard,
                    glass_myopia_atrr:item.glass_myopia_atrr,
                    glass_myopia_atrr_format:item.glass_myopia_atrr_format
                }
                skuTemp.push(skuItem)
            })
            skuTemp.map(item=>{
                item.sku_atrr.map(childItem=>{
                    item[childItem.arr_id] = childItem.arr_val_id;
                })
            })
            this.sku.list = skuTemp;
            //拼接sku.list end
            //设置spu普通属性 start
            this.spu_g_attr = res.res.g_atrr;
        },
        // 验证是否有不正确的表单信息
        verify(){
            let value1 = false;
            let value2 = false;
            if(this.specialFormData.rightCount > 0){
                if(
                    this.specialFormData.rightGuang.error == false && this.specialFormData.rightGuang.value !== '' &&
                    this.specialFormData.rightSan.error == false&& this.specialFormData.rightSan.value !== '' &&
                    this.specialFormData.rightXia.error == false&& this.specialFormData.rightXia.value !== ''){
                    value1 = true
                }else{
                    value1 = false
                }
            }else{
                value1 = true
            }
            if(this.specialFormData.leftCount > 0){
                if(
                    this.specialFormData.leftGuang.error == false&& this.specialFormData.leftGuang.value !== '' &&
                    this.specialFormData.leftSan.error == false&& this.specialFormData.leftSan.value !== '' &&
                    this.specialFormData.leftXia.error == false&& this.specialFormData.leftXia.value !== ''){
                    value2 = true
                }else {
                    value2 = false
                }
            }else{
                value2 = true
            }
            return value1 && value2
        },
        //添加购物车方法
        onAddCartClicked(skuData){
            if(!this.verify()){
                this.$toast("请输入正确格式数据!")
                return false
            }
            let rightGuang = this.formatGuangSan(this.specialFormData.rightGuangPlusMinus,this.specialFormData.rightGuang.value);
            let rightSan = this.formatGuangSan(this.specialFormData.rightSanPlusMinus,this.specialFormData.rightSan.value);
            let leftGuang = this.formatGuangSan(this.specialFormData.leftGuangPlusMinus,this.specialFormData.leftGuang.value);
            let leftSan = this.formatGuangSan(this.specialFormData.leftSanPlusMinus,this.specialFormData.leftSan.value);
            if(this.type == 'edit'){
                let selectAtrrs = [];
                if(skuData.selectedSkuComb.sku_atrr){ //如果是单规格的是没有这个属性的
                    skuData.selectedSkuComb.sku_atrr.map(item=>{
                        let atrrsItem = { 
                            arr_id: item.arr_id,
                            val: item.arr_val_id,
                            type: "1"
                        }
                        selectAtrrs.push(atrrsItem)
                    })
                }
                //如果是眼镜片品  因为外层没有购物车id 所以取选中的id
                let shoppingcar_item = this.shoppingcar_item;
                // 判断规格是否相同
                if(this.selectedSkuComb && (this.selectedSkuComb.id == shoppingcar_item.skuId)){
                    shoppingcar_item.glass_myopia_atrr.attr_val.map(item=>{
                        if(item.left_or_right_eyes == '2'){
                            if(item.myopia == Number(rightGuang).toFixed(2) && 
                            item.astigmatism == Number(rightSan).toFixed(2) &&
                            item.add_light == this.specialFormData.rightXia.value && 
                            item.pd == this.specialFormData.rightTong.value &&
                            item.cylinder_axis == this.specialFormData.rightSanZhou.value)
                            {
                                this.shoppingcar_id2[0] = item.shoppingcar_id;
                            }
                        }
                        if(item.left_or_right_eyes == '1'){
                            if(item.myopia == Number(leftGuang).toFixed(2) && 
                            item.astigmatism == Number(leftSan).toFixed(2) &&
                            item.add_light == this.specialFormData.leftXia.value && 
                            item.pd == this.specialFormData.leftTong.value &&
                            item.cylinder_axis == this.specialFormData.leftSanZhou.value)
                            {
                                this.shoppingcar_id2[1] = item.shoppingcar_id;
                            }
                        }
                    })
                }
                let rightItem = {
                    spuId:skuData.goodsId,
                    skuId:skuData.selectedSkuComb.id,
                    sku_measure_id:this.sku.none_sku?this.sku.list[0].sku_measure_id:skuData.selectedSkuComb.sku_measure_id,
                    quantity:this.specialFormData.rightCount,
                    myopia:Number(rightGuang).toFixed(2)+'|'+Number(rightSan).toFixed(2),
                    addlight:Number(this.specialFormData.rightXia.value).toFixed(2),
                    leftOrRightEyes:'2',
                    shoppingId:this.shoppingcar_id2[0],
                    cylinderaxis:this.specialFormData.rightSanZhou.value,
                    pd:this.specialFormData.rightTong.value,
                    attrs:selectAtrrs
                }
                let leftItem = {
                    spuId:skuData.goodsId,
                    skuId:skuData.selectedSkuComb.id,
                    sku_measure_id:this.sku.none_sku?this.sku.list[0].sku_measure_id:skuData.selectedSkuComb.sku_measure_id,
                    quantity:this.specialFormData.leftCount,
                    myopia:Number(leftGuang).toFixed(2)+'|'+Number(leftSan).toFixed(2),
                    addlight:Number(this.specialFormData.leftXia.value).toFixed(2),
                    leftOrRightEyes:'1',
                    shoppingId:this.shoppingcar_id2[1],
                    cylinderaxis:this.specialFormData.leftSanZhou.value,
                    pd:this.specialFormData.leftTong.value,
                    attrs:selectAtrrs
                }
                let skulist = [];
                if(rightItem.quantity > 0){
                    skulist.push(rightItem)
                }
                if(leftItem.quantity > 0){
                    skulist.push(leftItem)
                }
                if(skulist.length < 1){
                    this.$toast("请选择商品数量")
                    return false
                }
                let data = {
                    skulist: skulist
                }
                if(this.shoppingcar_id2[0] || this.shoppingcar_id2[1]){//如果没有的话 那就是添加
                    cartEdit2(data).then(res=>{
                        if(res.code == 0){
                            this.$toast("修改成功")
                            this.$emit("getCartList")
                            // this.show = !this.show
                        }else{
                            this.$toast(res.msg)
                        }
                    })
                }else{
                    cartAdd2(data).then(res=>{
                        if(res.code == 0){
                            this.$toast("成功加入")
                            // this.show = !this.show;
                            this.$emit("getCartList")
                            //修改购物车图标组件中的个数
                            this.$store.dispatch('setCartCount')
                        }else{
                            this.$toast(res.msg)
                        }
                    })
                }
            }else{
                let selectAtrrs = [];
                if(skuData.selectedSkuComb.sku_atrr){ //如果是单规格的是没有这个属性的
                    skuData.selectedSkuComb.sku_atrr.map(item=>{
                        let atrrsItem = { 
                            arr_id: item.arr_id,
                            val: item.arr_val_id,
                            type: "1"
                        }
                        selectAtrrs.push(atrrsItem)
                    })
                }
                let rightItem = {
                    spuId:skuData.goodsId,
                    skuId:skuData.selectedSkuComb.id,
                    sku_measure_id:this.sku.none_sku?this.sku.list[0].sku_measure_id:skuData.selectedSkuComb.sku_measure_id,
                    quantity:this.specialFormData.rightCount,
                    myopia:Number(rightGuang).toFixed(2)+'|'+Number(rightSan).toFixed(2),
                    addlight:Number(this.specialFormData.rightXia.value).toFixed(2),
                    leftOrRightEyes:'2',
                    cylinderaxis:this.specialFormData.rightSanZhou.value,
                    pd:this.specialFormData.rightTong.value,
                    attrs:selectAtrrs
                }
                let leftItem = {
                    spuId:skuData.goodsId,
                    skuId:skuData.selectedSkuComb.id,
                    sku_measure_id:this.sku.none_sku?this.sku.list[0].sku_measure_id:skuData.selectedSkuComb.sku_measure_id,
                    quantity:this.specialFormData.leftCount,
                    myopia:Number(leftGuang).toFixed(2)+'|'+Number(leftSan).toFixed(2),
                    addlight:Number(this.specialFormData.leftXia.value).toFixed(2),
                    leftOrRightEyes:'1',
                    cylinderaxis:this.specialFormData.leftSanZhou.value,
                    pd:this.specialFormData.leftTong.value,
                    attrs:selectAtrrs
                }
                let skulist = [];
                if(rightItem.quantity > 0){
                    skulist.push(rightItem)
                }
                if(leftItem.quantity > 0){
                    skulist.push(leftItem)
                }
                if(skulist.length < 1){
                    this.$toast("请选择商品数量")
                    return false
                }
                let data = {
                    skulist: skulist
                }
                cartAdd2(data).then(res=>{
                    if(res.code == 0){
                        this.$toast("成功加入")
                        // this.show = !this.show;
                        //如果是重选 要删除一下旧商品
                        if(this.isChooseAgain){
                            this.$emit("invalidOne",this.shoppingcar_id2)
                        }
                        this.$emit("handleGetCartList")
                        //修改购物车图标组件中的个数
                        this.$store.dispatch('setCartCount')
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }
            
        },
        //监听skuinfo组件的show值修改skuShow
        handleUpdateSkuShow(v){
            this.skuShow = v
        }
    }
}
</script>