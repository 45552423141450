<style scoped>
    .sku-info-box{
        padding:0 1rem;
    }
    .sku-info-title{
        position: relative;
        padding: 1rem 0;
        color: #000000;
        font-size: 0.88rem;
    }
    .sku-info-title::after{
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        border-bottom: 0.0625rem solid #ebedf0;
        -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
        transform: scale(.5);
    }
    .sku-info-item{
        display: flex;
        align-items: flex-start;
        padding: 0.4rem 0;
        font-size: 0.8rem;
    }
    .sku-info-item-lable{
        width: 25%;
        font-size: 0.8rem;
        color: #3C3C3C;
    }
    .sku-info-item-con{
        width: 75%;
        font-size: 0.8rem;
        color: #030303;
    }
</style>
<template>
    <van-popup
        v-model="show"
        position="bottom"
        closeable
        round
        :style="{ height: '50%' }"
    >
    <div class="sku-info-box">
        <div class="sku-info-title">
            商品信息
        </div>
        <div class="sku-info-list">
            <div class="sku-info-item" v-for="item in g_attr" :key="item.arr_id">
               <div class="sku-info-item-lable"> {{item.arr_name}}</div>
               <div class="sku-info-item-con"> {{item.arr_val}}</div>
            </div>
        </div>
    </div>
    
    </van-popup>
</template>
<script>
export default {
    name:"sku-info",
    props:{
        skuShow:Boolean,
        spu_g_attr:Array
    },
    watch:{
        //监听父组件传来的show
        skuShow: {
            immediate: true,
            handler (v) {
                this.show = v;
            }
        },
        spu_g_attr:{
            immediate: true,
            handler (v) {
                this.g_attr = v;
            }
        },
        //修改父组件的show
        show(v){
            if(v === false){
                this.$emit("handleUpdateSkuShow",v)
            }
        }
    },
    data(){
        return{
            show:false,
            g_attr:[]
        }
    }
}
</script>