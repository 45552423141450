<template>
    <div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list
                class="good-list"
                v-model="loading"
                :finished="finished"
                finished-text="已经到底了"
                @load="onLoad">
                <div class="good-item" v-for="item in goodlist" :key="item.id">
                    <!-- <div class="good-img" @click="handleClickPreview(item)"> -->
                    <div class="good-img" @click="handleClickAddCart(item.goods_type,item.spuId)">
                        <van-image fit="contain"
                            class="img-box"
                            :src="getImgSizeJpg(item.image,3.8,3.8)">
                            <template v-slot:error>
                                <img style="width:100%;height:3.8rem" :src="defaultImg" />
                            </template>
                            <template v-slot:loading>
                                <img style="width:100%;height:3.8rem" :src="defaultImg" />
                            </template>
                        </van-image>
                    </div>
                    <div class="good-info"  @click="handleClickAddCart(item.goods_type,item.spuId)">
                        <div class="good-name">{{item.name}}</div>
                        <div class="good-price"><span style="font-weight:bold;font-size:0.9rem">{{item.price}}</span></div>
                        <!-- <div class="add-cart" @click="handleClickAddCart(item.type)">
                            <van-icon name="cart-circle-o" color="#ED6A0C" />
                        </div> -->
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
        <!-- 预览弹窗 -->
        <img-preview 
        :preViewList="preViewList"
        :propShow="propShowPreview"
         @handleClickPropShow="handleClickPropShowPreview"
         @handleClickAddCart="handleClickAddCart"></img-preview>
        <!--sku弹窗 -->
        <sku-glass-lens ref="skuPopup"
            :propShow="propShowGlass"
            :startSaleNumProp="startSaleNumProp"
            @handleClickPropShow="handleClickPropShowGlass"
            @handleGetCartList="handleGetCartList"></sku-glass-lens>
        <!-- 定制镜片sku弹窗 -->
        <sku-custom-lens ref="skuPopupCustom" 
            :propShow="propShowCustomGlass"
            :startSaleNumProp="startSaleNumProp"
            @handleClickPropShow="handleClickPropShowCustomGlass"
            @handleGetCartList="handleGetCartList"></sku-custom-lens>
    </div>
</template>
<script>
import './index.less'
import skuGlassLens from "@/view/Public/skuGlassLens"
import skuCustomLens from "@/view/Public/skuCustomLens"
import imgPreview from "@/components/ImgPreview"
import { goodsList }from "@/apis/api"
import defaultImg from "@/assets/image/default.png"
export default {
    name:"com-good-list",
    components:{ skuGlassLens, skuCustomLens, imgPreview},
    data(){
        return{
            defaultImg:defaultImg,
            startSaleNumProp:0,
            page:1,
            pageSize:10,
            isLoading:false,//下拉刷新loading
            loading: false,//上拉加载列表的数据
            finished: false,//上拉加载列表的数据
            goodlist:[],
            // 传到sku组件的数据 start
            // spuId:"",//点击的商品id
            propShowGlass:false,
            propShowCustomGlass:false,
            // 传到sku组件的数据 end
            // 传到预览图片组件的数据 start
            preViewList:{}, //要预览的图片信息
            propShowPreview:false
            // 传到预览图片组件的数据 end
        }
    },
    props:{
        salelabelId:{
            type:String,
            default:""
        }
    },
    methods:{
        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;

            // 重新加载数据
            this.page = 1
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        //根据类别获取商品列表
        onLoad() {
            let req = {
                salelabelId:this.salelabelId,
                // salelabelId:"",
                page:this.page,
                pageSize:this.pageSize
            }
            goodsList(req).then(res=>{
              if(res.code == 0){

                if (this.isLoading) {
                    this.goodlist = [];
                    this.isLoading = false;
                }
                this.page++;
                let newList = res.res.goods;
                if(newList.length < this.pageSize){
                    this.finished = true; 
                }
                this.goodlist = this.goodlist.concat(newList);
                // 加载状态结束
                this.loading = false;
                // 数据全部加载完成
                if(this.goodlist.length >= res.res.count){
                    this.finished = true;
                }
              }else{
                  this.$toast(res.msg)
              }
            })
        },
        //点击购物车按钮 显示sku弹窗
        handleClickAddCart(type,id){
            if(type == '3') {
                //点击触发子组件获取详情的方法
                this.$refs.skuPopupCustom.goodsDetail(id).then(()=>{
                    this.propShowCustomGlass = !this.propShowCustomGlass
                }).catch(error => {
                    this.$toast("获取商品信息失败")
                })
            }else{
                //点击触发子组件获取详情的方法
                this.$refs.skuPopup.goodsDetail(id).then(()=>{
                    this.propShowGlass = !this.propShowGlass
                }).catch(error => {
                    this.$toast("获取商品信息失败")
                })
            }
        },
        //点击预览图片
        handleClickPreview(item){
            if(item.image){
                this.propShowPreview = !this.propShowPreview;
                this.preViewList = item;
            }else{
                this.$toast("商家还没有上传商品图片")
            }
        },
        //切换sku 组件的propShow 
        handleClickPropShowGlass(v){
            this.propShowGlass = v
        },
        handleClickPropShowCustomGlass(v){
            this.propShowCustomGlass = v
        },
        //切换预览图片组件的propShow 
        handleClickPropShowPreview(v){
            this.propShowPreview = v
        },
        //触发父组件获取购物车列表
        handleGetCartList(){
            this.$emit("getCartList")
        }
    }
}
</script>